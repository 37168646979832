import { EventEmitter, Injectable } from '@angular/core';

/**
 * This service is for emitting filtered data in account-feed
 * @class AccountFeedFilterService
 */

@Injectable()
export class EmitterService {

    static isCreating: Boolean = false;
    static instance: EmitterService;
    filterDataEvent: EventEmitter<any> = new EventEmitter();

    static getInstance() {
        if (EmitterService.instance == null) {
            EmitterService.isCreating = true;
            EmitterService.instance = new EmitterService();
            EmitterService.isCreating = false;
        }
        return EmitterService.instance;
    }

    /**
     * Emits filter text entered in account-filter component
     * to account-history and notification component
     */

    sendData(data: any): void {
        this.filterDataEvent.emit(data);
    }
}
