import { Injectable } from '@angular/core';
var moment = require('moment');
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HTTPUtility } from '../utils/http-utility';
import {
  Headers,
  Http,
  Request,
  RequestMethod,
  RequestOptions,
  Response,
  ResponseContentType,
  ResponseOptions
} from '@angular/http';
import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
@Injectable({
  providedIn: 'root'
})
export class WialonApiService {
  hostUrl: any = "https://wialonapp.herokuapp.com/https://hst-api.wialon.com/wialon/ajax.html?";
  postionList: any = [];
  // This is trial key. Original key is yet to be provided by NVE.


  constructor(public htpp: HttpClient, private httpUtil: HTTPUtility, private https: Http) { }


  getAllUnit(): Promise<any> {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"avl_unit_group",
      "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', getunit, 'HOME', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }
  getUnit(): Promise<any> {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"avl_unit",
      "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', getunit, 'HOME', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }

  getSID(): Promise<any> {

    if (typeof (Storage) !== "undefined") {
      // Store

      // return Promise.resolve('09652c5fbbd1b1cb763e382213f74200');
      return Promise.resolve(sessionStorage.getItem('sid'));
    }
    return null;

    // const jwt = '"9ca268e88114385545b5db78f1bce54f14B3643C44FF0881CFB785D9DED310B11B0564E0"';
    // const siteURL: any = this.hostUrl + "svc=token/login&params={\"token\":" + jwt + "}";
    // return this.httpUtil.sendRequest('Post', siteURL, null).then((res) => {
    //   return Promise.resolve<any>(res.json().eid);
    // }).catch((err) => {

    //   return Promise.reject('');
    // });


  }
  getAvlGroupDetails() {
    return this.getSID().then((res) => {
      const groupURL: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"avl_unit_group",
            "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', groupURL, 'HOME', null, true).then(resp => {

        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('Error Occured While Fetching Group Details');
      });
    }).catch((err) => {
      return Promise.reject<any>('Invalid Session Id');
    });
  }

  getResourceDetails() {
    return this.getSID().then((res) => {
      const resourceURL: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"avl_resource",
            "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', resourceURL, 'HOME', null, true).then(resp => {

        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('Error Occure While Fetching Group Details');
      });
    }).catch((err) => {
      return Promise.reject<any>('Invalid Session Id');
    });
  }
  // create the fuel report





  getReportTripDontDelete(resourceID, tempID) {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=report/get_report_data&sid=` + res + `&params={"itemId":` + resourceID + `,"col":[` + tempID + `],"flags":0}`;
      return this.httpUtil.sendRequest('Post', getunit, 'Fuel', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }



  constructRequest(unitDetails, messageContent, access_token) {
    let requestList = []
    unitDetails.forEach(unit => {
      let str = `{"svc":"unit/exec_cmd","params":{"itemId":"${unit.unitID}","commandName":"__app__chatterbox_msg_virt","linkType":"vrt","param":"${messageContent}","timeout":0,"flags":0}}`
      requestList.push(str);
    });

    return this.getSID().then((res) => {

      let formData: FormData = new FormData();
      formData.set('params', `{"params":[${requestList.toString()}],"flags":0}`);
      formData.set('sid', res)


      return Promise.resolve<any>(formData);
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }


  getAllUser(): Promise<any> {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"user",
      "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', getunit, 'HOME', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }


  getDuplicate(): Promise<any> {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=core/duplicate&sid=` + res + `&params={"operateAs":"","continueCurrentSession":true,"checkService":"","restore":1,"appName":""}`;
      return this.httpUtil.sendRequest('Post', getunit, 'HOME', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }
  getSummary(stats: string[][]) {
    let result = [];
    stats.forEach(elem => {
      let obj = { name: elem[0], sumValue: elem[1] }
      result.push(obj);
    });
    return result;
  }
  getTableData(header: string[], tableRes: any[]) {

  }
  constructLogReport(res: any) {
    let result: RootObject = res;
    let reportData = { resulttable: [], workingReport: [], idleReport: [], ecoReport: [], contReport: [] }
    let resulttable = []
    result.header[1].reportResult.tables.forEach((table, index) => {
      if (table.name == 'unit_group_ecodriving' && table.label == 'Eco driving (Full list)') {
        resulttable = this.constructEcoReport(table, result.tableresult[index])
        reportData.ecoReport = result.tableresult[index];
      } else if (table.name == 'unit_group_engine_hours' && table.label == 'Idling ( 30 min)') {
        this.constructIdleReport(table, result.tableresult[index], resulttable)
        reportData.idleReport = result.tableresult[index];
      } else if (table.name == 'unit_group_chronology' && table.label == 'Cont. Drive') {
        this.constructContinueReport(table, result.tableresult[index], resulttable)
        reportData.contReport = result.tableresult[index];
      } else if (table.name == 'unit_group_engine_hours' && table.label == 'Working hour') {
        this.constructWorkingHourReport(result.tableresult[index], resulttable)
        reportData.workingReport = result.tableresult[index];
      }

    });
    reportData.resulttable = resulttable;
    return reportData
  }


  constructWorkingHourReport(workingReport, resulttable) {
    //["№", "Grouping", "Engine Hour", "Beginning", "End", "Total working hour", "Total Drive hour", "Idling", "Mileage", "Avg speed", "Max speed",

    workingReport.forEach(element => {
      let unitIndex = this.unitArray.indexOf(element.c[1]);
      if (unitIndex < 0) {
        //resulttable[unitIndex]['idleCount'] = idleCount;
      } else {
        resulttable[unitIndex]['Total working hour'] = element.c[5];
        resulttable[unitIndex]['Total Drive hour'] = element.c[6];
        resulttable[unitIndex]['Idling'] = element.c[7];
        resulttable[unitIndex]['Mileage'] = element.c[8];
        resulttable[unitIndex]['Avg speed'] = element.c[9];
        resulttable[unitIndex]['Max speed'] = element.c[10].t ? element.c[10].t : '0 Km/h';
        resulttable[unitIndex]['Max Duty'] = 0;
        resulttable[unitIndex]['Rest'] = 0;
        resulttable[unitIndex]['Cont45'] = 0;
        element.r.forEach((level1Data, levelIndex) => {

          let myVar = moment.duration(level1Data.c[6]).asSeconds()
          if (myVar > 36000) {
            resulttable[unitIndex]['Max Duty'] += 1;
            resulttable[unitIndex]['totalVialotion'] += 1;
          }
          if (element.r[levelIndex + 1]) {
            let Rest = element.r[levelIndex + 1].c[3].v - level1Data.c[4].v;
            if (Rest < 28800) {
              resulttable[unitIndex]['Rest'] += 1;
              resulttable[unitIndex]['totalVialotion'] += 1;
            }
          }

        });
        //"Grouping", "Engine Hour", "Beginning", "End", "Total working hour", "Total Drive hour", "Idling", "Mileage", "Avg speed", "Max speed",
      }
    });
  }

  constructIdleReport(table: Table, idleReportData, resulttable) {
    idleReportData.forEach(element => {
      let unitIndex = this.unitArray.indexOf(element.c[1]);
      let idleCount = element.r.length;
      if (element.t1 == 0) {
        idleCount = 0;
      }
      if (unitIndex < 0) {
        //resulttable[unitIndex]['idleCount'] = idleCount;
      } else {
        resulttable[unitIndex]['idleCount'] = idleCount;
        resulttable[unitIndex]['totalVialotion'] += idleCount;
      }
    });

  }



  constructContinueReport(table: Table, idleReportData, resulttable) {

    idleReportData.forEach(element => {
      let unitIndex = this.unitArray.indexOf(element.c[1]);
      let idleCount = element.r.length;
      if (element.t1 == 0) {
        idleCount = 0;
      }
      if (unitIndex < 0) {
        //resulttable[unitIndex]['idleCount'] = idleCount;
      } else {
        resulttable[unitIndex]['contDrive'] = idleCount;
        resulttable[unitIndex]['totalVialotion'] += idleCount;

      }
    });

  }
  unitArray = [];
  constructEcoReport(table: Table, ecoReportData) {
    let ecoData = [];
    this.unitArray = [];
    ecoReportData.forEach(element => {
      //80 - 84 km/h
      //       85 - 89 km/h: "35"
      // 90 - 94 km/h: "7"
      // 95 - 105 km/h: "0"
      // 106 - 115 km/h: "0"
      // 116 - 125 km/h: "0"
      // 126 - 180 km/h: "0"
      // Harsh Acceleration: "0"
      // Harsh Braking: "0"
      // Harsh cornering: "2"
      // Speeding 1 - 20km/h: "0"
      // Speeding 20 - 30km/h: "0"
      // Speeding &gt;30km/h: "0"

      let isKey = false;
      let isKeyOne = false;
      let keyName = ''
      if (element.c[1] == 'Speeding &gt;30km/h') {
        keyName = 'gt30'
        isKey = true
      } else if (element.c[1] == 'Speeding 1 - 20km/h') {
        keyName = 'gt10'
        isKey = true
      }
      else if (element.c[1] == 'Speeding 20 - 30km/h') {
        keyName = 'gt20'
        isKey = true
      } else if (element.c[1] == 'Harsh Braking') {
        keyName = 'harshBr'
        isKey = true
      } else if (element.c[1] == 'Harsh cornering') {
        keyName = 'harshCo'
        isKey = true
      } else if (element.c[1] == 'Harsh Acceleration') {
        keyName = 'harshAc'
        isKey = true
      }
      else {

        isKeyOne = true;
        if (element.c[1] == '80 - 84 km/h') {
          keyName = 'obj80-84'
        } else if (element.c[1] == '85 - 89 km/h') {

          keyName = 'obj85-89'
        } else if (element.c[1] == '90 - 94 km/h') {
          keyName = 'obj90-94'
        } else if (element.c[1] == '95 - 105 km/h') {
          keyName = 'obj95-105'
        }
        else if (element.c[1] == '106 - 115 km/h') {
          keyName = 'obj106-115'
        }
        else if (element.c[1] == '116 - 125 km/h') {
          keyName = 'obj116-125'
        }
        else if (element.c[1] == '126 - 180 km/h') {
          keyName = 'obj126-180'
        } else {
          isKeyOne = false;
          keyName = element.c[1]
        }

      }
      element.r.forEach(data => {

        let l = { count: data.c[5], lableName: element.c[1], keyName: keyName }
        let unitIndex = this.unitArray.indexOf(data.c[1]);
        if (unitIndex < 0) {

          let dataC = { unitName: '', ecoDriving: [], totalVialotion: 0, totalCustomVialotion: 0 }
          this.unitArray.push(data.c[1])
          dataC.unitName = data.c[1]

          ecoData.push(dataC);

          ecoData[ecoData.length - 1][keyName] = data.c[5]

          if (isKey && data.c[5]) {
            ecoData[ecoData.length - 1]['totalVialotion'] = data.c[5]
          } else if (data.c[5] && isKeyOne) {
            ecoData[ecoData.length - 1]['totalCustomVialotion'] = data.c[5]
          }
        } else {
          ecoData[unitIndex][keyName] = data.c[5]
          if (data.c[5] && isKey) {
            ecoData[unitIndex]['totalVialotion'] = Number(ecoData[unitIndex]['totalVialotion']) + Number(data.c[5])
          } else if (data.c[5] && isKeyOne) {
            ecoData[unitIndex]['totalCustomVialotion'] = Number(ecoData[unitIndex]['totalCustomVialotion']) + Number(data.c[5])

          }
        }
      });
    });
    return ecoData;
  }
  timeDiff(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hr " + rminutes + " min.";
  }
  genrateReprot(fromDate, toDate, unitID, userID, templateID) {

    // return this.constructLogReport('');
    return this.getSID().then((res) => {

      const reportTable: any = `https://cors-anywhere.herokuapp.com/https://hst-api.wialon.com/wialon/ajax.html?svc=core/batch&params={"params":[{"svc":"report/cleanup_result","params":{}},{"svc":"report/exec_report","params":{"reportResourceId":${userID},"reportTemplateId":${templateID},"reportTemplate":null,"reportObjectId":${unitID},"reportObjectSecId":0,"interval":{"flags":16777216,"from":${fromDate},"to":${toDate}},"reportObjectIdList":[]}}],"flags":0}&sid=${res}`
      return this.httpUtil.sendRequest('Post', reportTable).then((reportHeaderRes) => {
        let tables = reportHeaderRes.json()[1].reportResult.tables;


        const reportTable: any = `https://cors-anywhere.herokuapp.com/https://hst-api.wialon.com/wialon/ajax.html?svc=core/batch&params={"params":[{"svc":"report/select_result_rows","params":{"tableIndex":0,"config":{"type":"range","data":{"from":0,"to":${tables[0].rows},"level":2}}}},
        {"svc":"report/select_result_rows","params":{"tableIndex":1,"config":{"type":"range","data":{"from":0,"to":${tables[1].rows},"level":0}}}},
        {"svc":"report/select_result_rows","params":{"tableIndex":2,"config":{"type":"range","data":{"from":0,"to":${tables[2].rows},"level":1}}}},
        {"svc":"report/select_result_rows","params":{"tableIndex":3,"config":{"type":"range","data":{"from":0,"to":${tables[3].rows},"level":1}}}},
        {"svc":"report/select_result_rows","params":{"tableIndex":4,"config":{"type":"range","data":{"from":0,"to":${tables[4].rows},"level":2}}}}],"flags":0}&sid=${res}`

        return this.httpUtil.sendRequest('Post', reportTable).then((reportTableResult) => {
          let result: any = { 'header': reportHeaderRes.json(), 'tableresult': reportTableResult.json() };
          return this.constructLogReport(result);
          // return Promise.resolve<any>(result);
        }).catch((err) => {
          console.log(err)

        });
      }).catch((err) => {
        console.log(err)
      });

    }).catch((err) => {

    });
  }

  rad(x) {
    return x * Math.PI / 180;
  };

  getDistance(p1, p2) {

    var R = 6378137; // Earth’s mean radius in meter
    var dLat = this.rad(p2.lat - p1.lat);
    var dLong = this.rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.fround(d / 1000); // returns the distance in meter
  }
}



export interface Nested {
  type: string;
}

export interface Grouping {
  nested: Nested;
  sortAscend: number;
  sortColumn: number;
  type: string;
}

export interface TotalRaw {
  v: number;
  vt: number;
}

export interface Table {
  name: string;
  label: string;
  grouping: Grouping;
  flags: number;
  rows: number;
  level: number;
  columns: number;
  header: string[];
  header_type: string[];
  total: string[];
  totalRaw: TotalRaw[];
}

export interface ReportResult {
  msgsRendered: number;
  stats: any[];
  tables: Table[];
  attachments: any[];
}

export interface Header {
  error: number;
  reportResult: ReportResult;
}

export interface RootObject {
  header: Header[];
  tableresult: any[][];
}




