import {
    Headers,
    Http,
    Request,
    RequestMethod,
    RequestOptions,
    Response,
    ResponseContentType,
    ResponseOptions
} from '@angular/http';

import { EventEmitter, Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import {Observable} from 'rxjs';
@Injectable({
    providedIn: 'root'
  })
export class HTTPUtility {

    toggleUILoader: EventEmitter<boolean> = new EventEmitter<boolean>();
    deviceInfo: string;

    httpRequest: any;
    constructor(private http: Http) {
    }

    sendRequest(httpMethod: any, requestURL: string, requestBody?: any, requestHeaders?: Headers, showLoader?: boolean): Promise<Response> {
        requestHeaders = requestHeaders || new Headers();
        requestHeaders.append('Access-Control-Allow-Origin', '*');
        requestHeaders.append('Content-Type', 'application/json');
      
        requestHeaders.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        let requestObject: Request = new Request(new RequestOptions({
            url: requestURL,
            method: httpMethod,
            headers: requestHeaders,
            body: requestBody
        }));
        if (showLoader) {
            this.toggleUILoader.emit(true);
        }

        return this.http.request(requestObject).toPromise().then(
            res => {
                if (showLoader) {
                    this.toggleUILoader.emit(false);
                }
                return res;
            }
        ).catch(
            res => {
                if (showLoader && requestBody === 'HOME') {
                    this.toggleUILoader.emit(true);
                }
                return res;
            }
            );
    }

    sendURLENCODERequest(httpMethod: any, requestURL: string, requestBody?: FormData, requestHeaders?: Headers, showLoader?: boolean): Promise<Response> {
        requestHeaders = requestHeaders || new Headers();
        requestHeaders.append('Access-Control-Allow-Origin', '*');
        requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

     //   requestHeaders.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
        let requestObject: Request = new Request(new RequestOptions({
            url: requestURL,
            method: httpMethod,
            headers: requestHeaders,
            body: 'params='+requestBody.get('params')
        }));
        if (showLoader) {
            this.toggleUILoader.emit(true);
        }

        return this.http.request(requestObject).toPromise().then(
            res => {
                if (showLoader) {
                    this.toggleUILoader.emit(false);
                }
                return res;
            }
        ).catch(
            res => {
               
                return res;
            }
            );
    }
}



