import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router) { 

      const queryParams = window.location.search;
      const searchParams = new URLSearchParams(queryParams);
      let sid = searchParams.get('sid');
      if (!sid) {
        sid = searchParams.get('?sid');
      }
      if (typeof (Storage) !== "undefined") {
        // Store
        sessionStorage.setItem("sid", sid);
      }
    
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
